<template>
	<div style="background-color: #FFFFFF; min-width: 1400px;">
		<!-- <div  class="fl-s-c" style="  margin-bottom: 20px;">
				<span class="s-title">{{$t('m.Professionalintegrityoftechnicalservices')}}</span>
		</div> -->
		<div class="componyzz fl-s-c">
				<span class="s-title">{{$t('m.price')}}</span>
				<div class="w-1 relative fr-b-c" v-if="productlist.length" style="margin-top: 50px;">
						<div @click="toDetail(item.id,item.title_name)" class="pointer zz-item" v-for="(item,index) in productlist" :key="index">
							<span style="font-size: 20px;font-weight: bold;color: #222222;">{{item.title_name}}</span>
							<img :src="imgurl+item.url_image" class="zz-img"  style="margin-top: 15px;">
							<div class="line3" v-html="item.textcontent"></div>
							<div style="font-weight: 400;color: #999999;">
								查看详情  <i class="el-icon-right"></i>
							</div>
						</div>					
				</div>
			</div>
		 
	
			<!-- 智能 & 创造 -->
			<!-- <div class="fl-s-c" style="min-width: 1400px; background-color: #f8f8fa;">
				<div class="intelligence fl-s-c">
					<strong class="s-title">{{$t('m.Intelligence&Creation')}}</strong>
					<div class="w-1 fr-b-c" style="margin-top: 3.125rem;">
						<div class="fl-c pointer" @click="toTDetail(item.id,item.name,item.type)" v-for="(item,index) in menuItems" :key="index">
							<img :src="item.url_image" class="cover" style="border-radius: 50%; width: 87px; height: 87px;">
							<span class="menu-t">{{item.name}}</span>
						</div>
					</div>
				</div>
			</div>	
			 -->
			<!-- 技术服务专业诚信 -->
			<!-- <div class="sincerity fl-s-c">
				<span class="s-title">{{$t('m.Professionalintegrityoftechnicalservices')}}</span>
				<div class="w-1 fr-b-c" style="margin-top: 3.125rem;">
					<div class="sincerity-item fl-s-c" v-for="(item,index) in datanology" :key="index" v-if="index<4">
						<div class="w-1 item-top fr-c">
							{{item.name}}
						</div>
						<div class="text-box relative">
						
							<div class="w-1 h-1" v-if="item.content" v-html="item.content"></div>
						</div>
					<el-divider class="row-line"></el-divider>
					<router-link 
					:to="{
						path:item.type==null?'/':item.type=='table'?'/latestbroadcast':item.type=='list'?'/salesdepartmentukbranch':item.type=='page'?'/about':'/qualification',
						query:{
							pname:item.name,
							pid:item.id
						}
					}">{{$t('m.Viewdetails')}}</router-link>
					</div>
				</div>
			</div> -->
			<!-- 最新项目播报 -->
			<div class="newProject relative fl-s-c">
				<img src="@/assets/imgs/home/pro_bg.png" class="bgImage">
				<span class="s-title">{{$t('m.Latestprojectbroadcast')}}</span>
				<div class="relative pro-b-module fr-s-c">
					<div class="fl-s-c relative p-item" v-for="(item,index) in databroad" :key="index" v-if="index<4">
						<p class="p-title">{{item.name}}</p>
						<div class="relative w-1" style="overflow: hidden; font-size: 14px; height:100%; padding: 0 25px; margin-top: 30px;">
							<div class="w-1 h-1" v-if="item.content" v-html="item.content"></div>
							<!-- <div class="w-1 h-1" v-if="item.content" v-html="formatRichText(item.content)"></div> -->
						</div>
						<router-link class="fr-c p-detail" 
						:to="{
							path:item.type==null?'/':item.type=='table'?'/latestbroadcast':item.type=='list'?'/salesdepartmentukbranch':item.type=='page'?'/about':'/qualification',
							query:{
								pname:item.name,
								pid:item.id
							}
						}"
						>{{$t('m.Viewdetails')}}</router-link>
					</div>
				</div>
			</div>
			<!-- 公司资质 -->
			<div class="componyzz fl-s-c">
				<span class="s-title">{{$t('m.Companyqualification')}}</span>
				<div class="w-1 relative fr-b-c" v-if="datafication.length" style="margin-top: 50px;">
						<div @click="toDetail(item.id,item.title_name)" class="fl-c pointer zz-item" v-for="(item,index) in datafication" :key="index">
							<img :src="imgurl+item.url_image" class="zz-img" >
							<span style="margin-top:20px; font-size: 16px; color: #555555;">{{item.title_name}}</span>
						</div>					
				</div>
			</div>
			
	</div>
</template>

<script>
import url from "../request/base";
	import formatRichText from '../utils/util.js'
	export default {
		data(){
			return{
				 imgurl: url.baseUrl,
				menuItems:[
					{
						text:'m.Companyprofile',
						img:require('@/assets/imgs/home/icon1.png')
					},{
						text:'m.Qualificationcertification',
						img:require('@/assets/imgs/home/icon2.png')
					},{
						text:'m.Safeoperation',
						img:require('@/assets/imgs/home/icon3.png')
					},{
						text:'m.Integratedsales',
						img:require('@/assets/imgs/home/icon4.png')
					},{
						text:'m.Pastperformance',
						img:require('@/assets/imgs/home/icon5.png')
					},{
						text:'m.contactus',
						img:require('@/assets/imgs/home/icon6.png')
					},
				],
				databroad:[],
				datafication:[],   // 公司资质
				datanology:[],
				productlist:[]
			}
		},
		created(){
			// console.log(this.$t('m.Telephone'),'++++++++++')
			this.getHomeC()
			this.getnavList()
			this.product()
		},
		methods:{
			formatRichText,
			getnavList(){
				this.$api.home.getnavList({}).then(res=>{
					res.data.mendedList.push({
						id:'c1',
						name:this.$t('m.contactus'),
						type:'c',
						url_image:require('@/assets/imgs/home/icon6.png')
					})
					this.menuItems = res.data.mendedList
					console.log(this.menuItems)
					// console.log(children)
				})
			},
			product(){
				this.$api.home.productIndex({}).then(res=>{
					console.log(res,'2121212');
					this.productlist=res.data
				})
				
			},
			getHomeC(){
				this.$api.home.getHomeC({}).then(res=>{
					console.log(res,'首页分类啦啦啦啦啦啦')
					this.datanology = res.data.datanology
					this.databroad = res.data.databroad
					this.datafication = res.data.datafication
				})
			},
			toTDetail(id,name,type){
				console.log(id,name,type)
				this.$router.push({
					path:type==null?'/':type=='table'?'/latestbroadcast':type=='list'?'/salesdepartmentukbranch':type=='page'?'/about':type=='imglist'?'/qualification':'/contact',
					query:type!='c'?{
						pid:id,
						pname:name
					}:{}
				})
			},
			// 资质详情
			toDetail(id,name){
				this.$router.push({
					path:'/technicaldetail',
					query:{
						pid:id,pname:name
					}
				})
			}
		}
	}
</script>

<style scoped>
.line3{
   width: 100%;
   overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3; /*显示两行*/
  height: 64px;
}
	.intelligence{
		width: 1200px;
		padding: 50px 0;
		background: #f8f8fa;
	}
	.s-title{
		color: #222222;
		font-size: 24px;
	}
	.menu-t{
		margin-top: 15px;
		font-size: 18px;
		color: #222222;
	}
	.sincerity{
        width: 1400px;
				margin: auto;
				padding: 50px 0;
	}
	.sincerity-item{
		width: 350px;
		height: 509px;
		border: 1px solid #DFDFDF;
	}
	.item-top{
		background-color: #ebebed;
		color: #333333;
		font-size: 18px;
		height: 128px;
	}
	.text-box{
		width: 294px;
		height: 224px;
		font-size: 14px;
		color: #333333;
		line-height: 18px;
		overflow: hidden;
		margin-top: 40px;
	}
	.row-line{
		width: 250px;
		margin: 0;
		background: #999999;
	}
	.sincerity-item a{
		font-size: 18px;
		color: #333333;
		margin-top: 38px;
	}
	.sincerity-item:hover{
		border: 1px solid #2668b4;
	}
	.sincerity-item:hover .item-top{
		background-color: #2668b4;
		color: #FEDE5C;
	}
	.sincerity-item a:hover{
		color: #fb4937;
	}
	.newProject{
    min-width: 1400px;
		margin: auto;
	}
	.bgImage{
		height: 438px;
		position: absolute;
		left: 0;
		top: 70px;
    width: 100%;
	}
	.pro-b-module{
		width: 1200px;
		 margin-top: 38px;
		 height: 438px;
		 color: #FFFFFF;
	}
	.p-title{
		font-size: 20px;
		
	}
	
	.p-item{
		width: 300px;
		height: 438px;
		padding: 70px 0;
	}
	.p-item>a{
		width: 190px;
		height: 38px;
		border: 1px solid #FFFFFF;
		font-size: 12px;
		color: #FFFFFF;
	}
	.p-item>a:hover{
		background-color: #FFFFFF;
		color: #94C019;
	}
	.p-detail{
		margin-top: 48px;
	}
	
	.componyzz{
		width: 1200px;
		margin: auto;
		padding: 50px 0px;
	}
	.zz-img{
		width: 380px;
		height: 285px;
		/* object-fit: contain; */
	}
	.zz-item{
		width: 380px;
	}
</style>
